<template>
    <router-view />
</template>

<script>
import { getUserInfo } from "@/service/account";
import { getSystemConfig } from "@/service/system";
export default {
    name: "App",
    props: [],
    components: {},
    data: function () {
        return {};
    },
    computed: {
        token() {
            return this.$route.query.token || localStorage.getItem("token");
        },
    },
    watch: {
        "$route.query.token": {
            handler: function (val) {
                if (val) {
                    localStorage.setItem("token", val);
                    this.$store.commit("setToken", val);

                    getUserInfo(val).then((res) => {
                        this.$store.commit("SET_USER_INFO", res.data.data);

                        if (!res.data?.data?.wx_mp_openid) {
                            // 强制弹窗
                            this.$confirm("您还未绑定微信公众号，绑定后可享受更多服务", "提示", {
                                confirmButtonText: "去绑定",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {
                                    this.$router.push({ name: "user_wechat" });
                                })
                                .catch(() => {
                                    this.$router.push("/index");
                                });
                        }
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        async loadPhone(key = "express_phone_sys") {
            try {
                let phone = sessionStorage.getItem(key);
                if (phone) {
                    this.$store.commit("SET_STATE", { key, value: phone });
                    return;
                } else {
                    let phone = await getSystemConfig(key, this.token).then((res) => {
                        return res.data?.data || "";
                    });
                    this.$store.commit("SET_STATE", { key, value: phone });
                    sessionStorage.setItem(key, phone);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    created: function () {},
    mounted: function () {
        if (this.token) {
            this.loadPhone();
            this.loadPhone("express_phone_erp");
        }
    },
};
</script>

<style lang="less">
@import "@/assets/css/app.less";
</style>
