// 1.Create APP
import { createApp } from "vue";
import store from "./store/index";
import router from "./router/index";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";

function init() {
    const app = createApp(App);
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component);
    }
    app.use(store);
    app.use(router);
    app.use(ElementPlus);
    app.mount("#app");
}

init();
