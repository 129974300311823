const routes = [
    {
        path: "/code",
        name: "code_index",
        redirect: "/code/active",
        component: () => import("../views/code/index.vue"),
        children: [{ name: "code_active", path: "active", component: () => import("../views/code/code_active.vue") }],
    },
];

export default routes;
