import { $cms } from "@/utils/https";

// 获取用户信息
function getUserInfo(auth) {
    return $cms({ auth }).get(`/api/cms/account/user/profile`);
}

// 更新用户信息
function updateUserInfo(data, auth) {
    return $cms({ auth }).put(`/api/cms/account/user/profile`, data);
}
// 更新用户头像
function updateUserAvatar(data, auth) {
    return $cms({ auth }).put(`/api/cms/account/user/profile/avatar`, data);
}
// 获取微信二维码
function getWechatCode(auth) {
    return $cms({ auth }).get(`/api/cms/wechat/mp/bind`);
}

// 绑定邮箱
function bindEmail(data) {
    return $cms().post(`/api/cms/user/email/bind`, data);
}

// 获取用户当前未验证邮箱
function getPendingEmail() {
    return $cms().get(`/api/cms/user/email/is_pending`);
}

// 重新发送验证邮件
function resendVerifyEmail() {
    return $cms().post(`/api/cms/user/email/resend`);
}

// 查询邮箱是否可用
function checkEmail(email) {
    return $cms().get(`/api/cms/user/email/is_available`, {
        params: {
            email,
        },
    });
}

// 更新邮箱
function updateEmail(data) {
    return $cms().post(`/api/cms/user/email/update`, data);
}

// 验证邮箱
function verifyEmail(params) {
    return $cms({ mute: true }).get(`/api/cms/user/email/verify`, {
        params,
    });
}

export {
    getUserInfo,
    updateUserInfo,
    updateUserAvatar,
    getWechatCode,
    bindEmail,
    getPendingEmail,
    resendVerifyEmail,
    checkEmail,
    updateEmail,
    verifyEmail,
};
