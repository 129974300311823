// 提交申请
const routes = [
    {
        path: "/apply",
        name: "apply_index",
        component: () => import("../views/apply/index.vue"),
        children: [
            { name: "add_accessory", path: "accessory", component: () => import("../views/apply/needs_accessory.vue") },
            {
                name: "edit_accessory",
                path: "accessory/:id",
                component: () => import("../views/apply/needs_accessory.vue"),
            },
            { name: "add_backup", path: "backup", component: () => import("../views/apply/needs_backup.vue") },
            { name: "edit_backup", path: "backup/:id", component: () => import("../views/apply/needs_backup.vue") },
            { name: "add_repair", path: "repair", component: () => import("../views/apply/needs_repair.vue") },
            { name: "edit_repair", path: "repair/:id", component: () => import("../views/apply/needs_repair.vue") },
            { name: "give_back", path: "give_back/:id", component: () => import("../views/dashboard/give_back.vue") },
            { name: "quick_repair", path: "quickrp", component: () => import("../views/apply/quick_repair.vue") },
            { name: "edit_quickrp", path: "quickrp/:id", component: () => import("../views/apply/quick_repair.vue") },
        ],
    },
];

export default routes;
