// 引导页
const routes = [
    {
        path: "/guide",
        name: "guide_index",
        component: () => import("../views/guide/index.vue"),
        children: [
            { name: "guide_accessory", path: "accessory", component: () => import("../views/guide/accessory.vue") },
            { name: "guide_backup", path: "backup", component: () => import("../views/guide/backup.vue") },
            { name: "guide_repair", path: "repair", component: () => import("../views/guide/repair.vue") },
        ],
    },
];

export default routes;
