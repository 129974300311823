const routes = [
    { name: "purchase", path: "/purchase", component: () => import("@/views/erp/purchase.vue") },
    {
        name: "purchase_single",
        path: "/purchase/:id",
        component: () => import("../views/erp/purchase_single.vue"),
    },
];

export default routes;
