const routes = [
    { name: "user_info", path: "/index", component: () => import("@/views/account/user.vue") },
    { name: "user_messages", path: "/i/message", component: () => import("@/views/account/message.vue") },
    { name: "user_email", path: "/i/email", component: () => import("@/views/account/email.vue") },
    { name: "user_wechat", path: "/i/wxmp", component: () => import("@/views/account/wechat.vue") },
    {
        name: "email_verify",
        path: "/account/email/verify",
        component: () => import("@/views/account/email_verify.vue"),
    },
];

export default routes;
