import { $cms } from "@/utils/https";

// 获取配置项
function getSystemConfig(key, auth) {
    return $cms({ auth, mute: true }).get(`/api/cms/system/config/key/${key}`);
}

function upload(formData) {
    return $cms().post(`/api/cms/system/upload/user`, formData);
}

export { getSystemConfig, upload };
