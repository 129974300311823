// 1.Dependency
import { createStore } from "vuex";

// 2.Store
const store = {
    state: {
        token: localStorage.getItem("token") || "",
        user: {},
        code: "",

        express_phone_erp: "",
        express_phone_sys: "",

        apply_info: {
            checked: -1,
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = "";
            localStorage.setItem("token", "");
        },
        SET_USER_INFO(state, user) {
            state.user = user;
        },
        SET_CODE(state, code) {
            state.code = code;
        },
        SET_STATE(state, { key, value }) {
            state[key] = value;
        },
    },
    getters: {},
    actions: {
        setApplyInfo({ commit }, info) {
            commit("SET_STATE", {
                key: "apply_info",
                value: info,
            });
        },
    },
};

export default createStore(store);
