const routes = [
    {
        path: "/dashboard",
        name: "dashboard_index",
        component: () => import("../views/dashboard/index.vue"),
        children: [
            {
                name: "accessory_list",
                path: "accessory",
                component: () => import("../views/dashboard/accessory_list.vue"),
            },
            {
                name: "accessory_single",
                path: "accessory/:id",
                component: () => import("../views/dashboard/accessory_single.vue"),
            },
            { name: "backup_list", path: "backup", component: () => import("../views/dashboard/backup_list.vue") },
            {
                name: "backup_single",
                path: "backup/:id",
                component: () => import("../views/dashboard/backup_single.vue"),
            },
            { name: "repair_list", path: "repair", component: () => import("../views/dashboard/repair_list.vue") },
            {
                name: "repair_single",
                path: "repair/:id",
                component: () => import("../views/dashboard/repair_single.vue"),
            },
            { name: "return_list", path: "return", component: () => import("../views/dashboard/return_list.vue") },
            { name: "profile", path: "profile", component: () => import("../views/profile") },
            {
                name: "quickrp_list",
                path: "quickrp",
                component: () => import("../views/dashboard/quickrp_list.vue"),
            },
            {
                name: "quickrp_single",
                path: "quickrp/:id",
                component: () => import("../views/dashboard/quickrp_single.vue"),
            },
        ],
    },
];

export default routes;
