// 1.Dependency
import {
    createRouter,
    // createWebHistory,
    createWebHashHistory,
} from "vue-router";
import { filter, flatten } from "lodash";
import { getUserInfo } from "@/service/account";
import store from "@/store";

// 2.Routes
const files = require.context("./", true, /\.js$/);
const routesModules = files.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = files(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});
const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("@/views/Index.vue"),
    },
    ...flatten(
        filter(routesModules, (module) => {
            return module;
        })
    ),
    { path: "/:pathMatch(.*)*", name: "NotFound", component: () => import("@/views/_/NotFound.vue") },
    { path: "/forbidden", name: "NotAuthenticated", component: () => import("@/views/_/NotAuthenticated.vue") },
];

// 3.Build An Instance
const router = createRouter({
    history: createWebHashHistory(), //hash
    // history: createWebHistory(),  //history api
    // base : '/rewrite root',
    routes,
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");
    if (token && to.name !== "profile") {
        getUserInfo(token).then((res) => {
            const data = res.data.data;
            store.commit("SET_USER_INFO", data);
            // 如果company或name为空，则跳转到profile页面
            if (!data.company || !data.name) {
                next({ name: "profile", query: { redirect: to.fullPath === "/" ? "/apply" : to.fullPath } });
            } else {
                next();
            }
        });
    } else {
        next();
    }
});

export default router;
